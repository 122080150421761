import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import RetinaImage from '../RetinaImage/RetinaImage';
// import DatedBanner from "../Banners/DatedBanner/DatedBanner";


import classes from './PublicHeader.module.scss';
import SecondaryButton from "../UI/Buttons/SecondaryButton";


class PublicHeader extends Component {


    goHome = () => {
        this.props.history.push("/");
    };

    isHome = () => {

        // console.log("test location:"+this.props.history.location.pathname);

        if (this.props.history.location.pathname === "/"){
            return true;
        }

        return false;
    }

    render() {

        return (
            <React.Fragment>

                {/*<DatedBanner code={"privacy-policy-update-dec-2022"} startDate={"2022/12/16"} endDate={"2022/12/31"}/>*/}

                <header className={classes.header}>

                    {
                    (this.isHome())
                        ?
                    <div className={classes.loginHeaderContainer}>
                        <Container>
                            <Row>
                                <Col className={"col-12 col-md-6"}>
                                    <img className={classes.loginHeaderLogo} src={require("../../assets/images/logos/ami-logo.svg").default} alt="Allergan Medical Institute" />
                                    <h1>Empowering excellence<br/> in surgery</h1>
                                </Col>
                                <Col className={"col-12 col-md-6"}>
                                    <RetinaImage className={""}
                                                onClick={this.goHome}
                                                height={400}
                                                src={[
                                                    require("../../assets/images/hero/login-hero.png").default,
                                                    require("../../assets/images/hero/login-hero@2x.png").default,
                                                    require("../../assets/images/hero/login-hero@3x.png").default]}
                                         alt="Allergan Medical Institue" />
                                    <p className={"p-small " + classes.loginHeaderImageText}>Not actual surgeon.</p>
                                </Col>
                            </Row>
                        </Container>

                    </div>
                        :
                    <Container className={classes.publicHeaderContainer}>
                        <Row>
                            <Col sm={6} className={"my-2"}>
                                {/*<RetinaImage className={classes.logoImg+" img-fluid"}*/}
                                {/*            onClick={this.goHome}*/}
                                {/*            width={393}*/}
                                {/*            height={38}*/}
                                {/*            src={[*/}
                                {/*                require("../../assets/images/logos/logo-allergan-ko.png").default,*/}
                                {/*                require("../../assets/images/logos/logo-allergan-ko@2x.png").default,*/}
                                {/*                require("../../assets/images/logos/logo-allergan-ko@3x.png").default]}*/}
                                {/*     alt="Allergan Medical Institue" />*/}
                                {/*<h1 className={classes.brandLogoText}>AMI</h1>*/}
                                <img className={"clickable"} onClick={() => this.props.history.push("/")} src={require("../../assets/images/logos/ami-logo.svg").default} alt="Allergan Medical Institute" />
                            </Col>
                            <Col sm={6} className={"my-2 d-flex justify-content-end"}>
                                                {/*<RetinaImage className={classes.logoImg+" img-fluid"}*/}
                                                {/*onClick={this.goHome}*/}
                                                {/*width={560}*/}
                                                {/*height={31}*/}
                                                {/*src={[*/}
                                                {/*    require("../../assets/images/logos/logo-pillar.png").default,*/}
                                                {/*    require("../../assets/images/logos/logo-pillar@2x.png").default,*/}
                                                {/*    require("../../assets/images/logos/logo-pillar@3x.png").default]}*/}
                                                {/*alt="Allergan Medical Institue" />*/}

                                {(this.isHome())? "":
                                    <React.Fragment>
                                        <SecondaryButton onClick={() => this.props.history.push("/")} className={""}>Login</SecondaryButton>
                                        <SecondaryButton onClick={() => this.props.history.push("/registration")} className={"ml-3"}>REGISTER FOR AN ACCOUNT</SecondaryButton>
                                    </React.Fragment>

                                }

                                <SecondaryButton onClick={() => this.props.history.push("/technical-support")} className={"ml-3"}>Technical Support</SecondaryButton>

                            </Col>
                        </Row>
                    </Container>
                    }

                </header>
            </React.Fragment>
        );
    }
}

export default withRouter(PublicHeader);
