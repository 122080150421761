import React from 'react';

const MobileHamburger = (props) => {

    let img;
    if(props.open){
        img = require("../../assets/images/icons/bars-regular-white.svg").default;
    } else {
        img = require("../../assets/images/icons/ico-close-white.svg").default;
    }

    return (
        <img width="30" height="30" src={img} alt=""/>
    );
};

export default MobileHamburger;
