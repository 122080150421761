import React, { Component } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import RetinaImage from '../RetinaImage/RetinaImage';
import BackToTop from '../BackToTop/BackToTop';
import classes from './Footer.module.scss';


class Footer extends Component {

    render() {
        const year = (new Date()).getFullYear();

        return (
            
            <footer className={classes.footer}>
                <BackToTop/>
                <Container className={"mt-5 mb-3"}>
                    <Row className={classes.rowSpacing + " pl-2 pl-lg-0"}>
                        <Col lg={2} className={"pl-0"}>
                            <a href="https://www.abbvie.com/" target="_blank" rel="noreferrer">
                                {/*<RetinaImage className={"img-fluid"}*/}
                                {/*             // onClick={this.goHome}*/}
                                {/*             width={241}*/}
                                {/*             height={70}*/}
                                {/*             src={[*/}
                                {/*    require("../../assets/images/logos/logos-ami-aesthetics.png").default,*/}
                                {/*    require("../../assets/images/logos/logos-ami-aesthetics@2x.png").default,*/}
                                {/*    require("../../assets/images/logos/logos-ami-aesthetics@3x.png").default]}*/}
                                {/*             alt="Allergan Medical Institue" />*/}
                                <img src={require("../../assets/images/logos/ami-logo.svg").default} alt="Allergan Medical Institute" />
                            </a>
                        </Col>
                        <Col className={"pt-4 pt-lg-2 pt-sm-4 ml-lg-3"}>
                            <Row>
                                <ul className={"p-small list-unstyled d-md-inline-flex para-4 mb-2 "+classes.link}>
                                    <li ><a href="https://www.abbvie.com/termsofuse.html" target="_blank" rel="noreferrer">Terms of Use</a></li>
                                    <li className="ml-md-4 mt-2 mt-md-0"><a href="https://www.abbvie.com/contactus.html" target="_blank" rel="noreferrer">Contact Us</a></li>
                                    <li className="ml-md-4 mt-2 mt-md-0"><a href="https://privacy.abbvie/privacy-policies/us-privacy-policy.html" target="_blank" rel="noreferrer">Privacy Notice</a></li>
                                    <li className="ml-md-4 mt-2 mt-md-0">
                                        <a className={"d-flex align-items-center"} href="https://abbviemetadata.my.site.com/AbbvieDSRM" target="_blank" rel="noreferrer">Your Privacy Choices
                                            <img alt="" className={"ml-1"} src={require("../../assets/images/icons/ico-privacy.png").default}/>
                                        </a>
                                    </li>
                                    {/*<li className="ml-md-4 mt-2 mt-md-0"><a href="https://www.allergan.com/privacy-and-terms/ccpa" target="_blank" rel="noreferrer">California Privacy</a></li>*/}
                                    {/*<li className="ml-md-4 mt-2 mt-md-0"><a href="https://www.allerganaesthetics.com" target="_blank" rel="noreferrer">AllerganAesthetics.com</a></li>*/}
                            {/*    </ul>*/}
                            {/*</Row>*/}


                            {/*<Row>*/}
                            {/*    <ul className={"list-unstyled d-md-inline-flex p-large mb-2"}>*/}
                                    <li className={"d-flex align-items-center pl-md-4 mt-2 mt-md-0"}>
                                        AMI  <a href="https://www.facebook.com/AllerganMedicalInstitute/"
                                                rel="noreferrer"
                                                className="d-flex align-items-baseline"
                                                target="_blank"><img className={"mx-1"} height="18" width="10" src={require("../../assets/images/icons/ico-fb.svg").default} alt="Fackbook"/></a>
                                        <a href="https://www.instagram.com/allergan_medical_institute/?hl=en"
                                           rel="noreferrer"
                                           className="d-flex align-items-baseline"
                                           target="_blank"><img  className={"mx-1"} height="15" width="15" src={require("../../assets/images/icons/ico-ig.svg").default}  alt=""/></a>
                                    </li>
                                    <li className="ml-md-4 mt-lg-0 d-flex mt-2 mt-md-0">
                                        Allergan Aesthetics <a href="https://www.facebook.com/AllerganAesthetics/"
                                                               rel="noreferrer"
                                                               className="d-flex align-items-baseline"
                                                               target="_blank"><img className={"mx-1"} height="18" width="10" src={require("../../assets/images/icons/ico-fb.svg").default} alt="Fackbook"/></a>
                                                        <a href="https://www.instagram.com/allerganaesthetics/?hl=en"
                                                           rel="noreferrer"
                                                           className="d-flex align-items-baseline"
                                                           target="_blank"><img  className={"mx-1"} height="15" width="15" src={require("../../assets/images/icons/ico-ig.svg").default}  alt=""/></a>
                                    </li>
                                </ul>
                            </Row>
                            <Row>
                                <p className={"mt-4 mt-lg-0 p-extra-small black"}>
                                    © {year} AbbVie. All rights reserved. Allergan Medical lnstitute and its design are trademarks of Allergan, Inc., an AbbVie company.<br/>
                                    All other trademarks are the property of their respective owners. MBD149163-v9 01/25
                                </p>
                            </Row>
                        </Col>

                    </Row>

                </Container>
            </footer>
        );
    }
}

export default Footer;
